import "./bootstrap";
import "core-js/modules/es.promise";

class BasePage {
    constructor() {
        this.init();
    }

    init() {
        var self = this;
        self.ready = false;
    }
}

export default BasePage;
