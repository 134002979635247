import "../../css/pages/main.scss";
import BasePage from "../modules/basePage.js";

class MainPage extends BasePage {
    constructor(...opts) {
        super(...opts);
    }

    init() {
        super.init();
    }
}

new MainPage();
